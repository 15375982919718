<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title="四川省级政策"
          :menuData="menuData"
          @handleMenuChange="handleMenuChange"
          :defaultCode="menu_id"
        ></vertical-menu>
        <!-- 政策介绍 -->
        <div class="right-list-box" v-if="menu_id=='kejizhengce'">
          <article-list @handleClick="articleClick" :data="dataList1"></article-list>
          <div class="footer">
            <div class="left">共 {{total1}} 条</div>

            <el-pagination
              background
              layout="prev, pager, next"
              :total="total1"
              :current-page.sync="queryParams1.pageNum"
              :page-size="queryParams1.pageSize"
              @current-change="getList1"
            ></el-pagination>
          </div>
        </div>
        <!-- 政策介绍 -->
        <div class="right-list-box" v-if="menu_id=='zhengcejiedu'">
          <article-list @handleClick="articleClick" :data="dataList2"></article-list>
          <div class="footer">
            <div class="left">共 {{total2}} 条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total2"
              :current-page.sync="queryParams2.pageNum"
              :page-size="queryParams2.pageSize"
              @current-change="getList2"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'


export default {
  name: 'Home',
  components: {
    articleList,
    verticalMenu,
  },
  data() {
    return {
      menuData: [{
        'name': '科技政策',
        'code': 'kejizhengce'
      },
      {
        'name': '政策解读',
        'code': 'zhengcejiedu'
      }
      ],
      menu_id: 'kejizhengce',
      dataList1: [],
      queryParams1: {
        pageNum: 1,
        pageSize: 6,
      },
      // 总条数
      total1: 0,
      dataList2: [],
      queryParams2: {
        pageNum: 1,
        pageSize: 6,
      },
      // 总条数
      total2: 0,
    }
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const menu_id = this.$route.query?.menu_id
    if (menu_id&&menu_id!='undefined') {
      console.log('options = ', options)
      this.menu_id = menu_id
    }
  },
  mounted() {
    this.getList1()
    this.getList2()
  },
  methods: {
    getList1() {
      const params = {
        ...this.queryParams1,
        'areaType': '2',
        'policyType': '2',
      }
      this.$api.policyHall.infoList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList1 = data.rows;
          this.total1 = data.total;
        }
      })
    },
    getList2() {
      const params = {
        ...this.queryParams2,
        'areaType': '2',
        'policyType': '3',
      }
      this.$api.policyHall.infoList(params).then((res) => {
        //console.log('res = ', res)
        const data = res.data
        if (data) {
          this.dataList2 = data.rows;
          this.total2 = data.total;
        }
      })
    },
    handleMenuChange(item) {
      console.log('handleMenuChange = ', item)
      this.menu_id = item.code
    },
    articleClick(item) {
      this.$router.push({ path: '/policyHall/page-sheng/articledetail', query: { id: item.id, 'detailType': 'policyHall-policy' } })
    },
    goback() {
      this.$router.back()
    },
    onSubmit() { },
  }
}
</script>
<style scoped lang="less">
.xiaozhenzhanshi {
  padding: 20px;
  flex: 3;
  flex-shrink: 0;
  min-width: 10px;

  // display: flex;
  // flex-direction: column;
  .content {
    display: flex;
    flex-direction: row;

    .single-line {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .sub-section-title {
    display: flex;
    justify-content: space-between;
    height: 40px;
    padding: 0 20px;

    .more {
      cursor: pointer;
    }
  }
}

.activity-grid-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 20px;

  .item-wrapper {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 20px 0;
    border: solid 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    .image {
      // width: 100%;
      height: 200px;
    }

    .title {
      height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding: 0 10px;
      margin-top: 10px;
    }

    .footer-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 30px;
      padding: 10px;

      .time {
      }

      .button {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }

    .sub-content {
      height: 40px;
      line-height: 20px;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 10px 10px 10px;
    }
  }
}
</style>
